import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';

const ProductsView = () => {
    return (
        <Box sx={{ width: '98%', m: 1.5 }}>
            <Helmet>
                <title>Órdenes</title>
            </Helmet>
            <Outlet />
        </Box>
    );
};

export default ProductsView;
