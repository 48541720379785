import {
    GraphicEqSharp,
    Inventory,
    ListAltTwoTone,
    LocalShipping,
    Warehouse
} from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupIcon from '@mui/icons-material/Group';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import { createElement } from 'react';

export const sidebarListItems = [
    /* 
    {
        title: 'Productos',
        path: '/app/products',
        icon:  React.createElement(Inventory2TwoToneIcon),
    },
    {
        title: 'Órdenes',
        path: '/app/orders',
        icon:  React.createElement(ListAltTwoToneIcon)
    },*/ {
        title: 'Dashboard',
        path: '/app/dashboard',
        icon: createElement(GraphicEqSharp),
        role: ['1', '5']
    },
    {
        title: 'Administración',
        icon: createElement(AdminPanelSettingsIcon),
        role: ['1', '5'],
        subItems: [
            {
                label: 'Gestión de usuarios',
                icon: createElement(GroupIcon),
                path: '/app/administration'
            },
            {
                label: 'Gestión de roles',
                icon: createElement(RoomPreferencesIcon),
                path: '/app/administration/roles'
            }
        ]
    },
    {
        title: 'Órdenes',
        path: '/app/orders',
        icon: createElement(ListAltTwoTone),
        role: ['1', '3', '5', '9']
    },
    {
        title: 'Productos',
        path: '/app/products',
        icon: createElement(Inventory),
        role: ['9']
    },
    {
        title: 'Catálogos',
        path: '/app/catalogs',
        icon: createElement(ListAltTwoTone),
        role: ['1', '3']
    },
    {
        title: 'Bodega',
        path: '/app/warehouse',
        icon: createElement(Warehouse),
        role: ['1', '2', '3', '5', '4', '7', '8', '9']
    },
    {
        title: 'Rastreo',
        path: '/app/tracking',
        icon: createElement(LocalShipping),
        role: ['1', '3', '4', '5', '9']
    }
];
