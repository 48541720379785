const channels = [
    {
        name: 'Todos',
        value: 'all'
    },
    {
        name: 'CREDITIENDA',
        value: 'CREDITIENDA'
    },
    {
        name: 'YUHU',
        value: 'YUHU'
    },
    {
        name: 'MERCADO LIBRE',
        value: 'MERCADOLIBRE'
    },
    {
        name: 'LINIO',
        value: 'LINIO'
    },
    {
        name: 'COPPEL',
        value: 'COPPEL'
    },
    {
        name: 'WALMART',
        value: 'WALMART'
    },
    {
        name: 'DOTO',
        value: 'DOTO'
    },
    {
        name: 'SHEIN',
        value: 'SHEIN'
    },
    {
        name: 'LIVERPOOL',
        value: 'LIVERPOOL'
    },
    {
        name: 'FORZA',
        value: 'FORZA'
    }
];

export default channels;
