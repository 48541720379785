import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createContext, useMemo, useState } from 'react';
import ThemeProvider from './theme/ThemeProvider';

const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
    const content = useRoutes(router);

    const [mode, setMode] = useState<'light' | 'dark'>('light');
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) =>
                    prevMode === 'light' ? 'dark' : 'light'
                );
                console.log(mode);
            }
        }),
        []
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CssBaseline />
                    {content}
                </LocalizationProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
export default App;
